import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const ExperimentalFeatureDialog = (): JSX.Element => {
    const router = useRouter();
    const { t } = useTranslation('common');

    const goBack = () => router.back();

    return (
        <Dialog aria-labelledby="experimental-feature-dialog" onClose={undefined} open>
            <DialogTitle id="experimental-feature-dialog">
                {t('experimentalFeature.title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary" id="experimental-feature-dialog">
                    {t(`experimentalFeature.errorMessage`)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={goBack} variant="outlined" autoFocus>
                    {t('experimentalFeature.goBack')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExperimentalFeatureDialog;
