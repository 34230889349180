import { CollectionName } from '@livv/models';
import { isAllowedToConsumePaidContent } from '@livv/utils/helpers';
import { collection, doc } from 'firebase/firestore';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { db } from '@config/firebase/client';
import { useAuth } from '@utils/context/auth';
import useUser from '@utils/context/user';

type UsePublicAccessCheckResponse = [boolean, boolean];

// routes that are allowed to be accessed without any restrictions
// because they are not related to any document in the database or firebase rules
const ALLOWED_ROUTES = ['/books'];
const COLLECTION_MAP = {
    books: CollectionName.TREES,
    decisions: CollectionName.DECISIONS,
};
const PUBLIC_ACCESS_COLLECTIONS = [CollectionName.DECISIONS, CollectionName.TREES];
const QUERY_KEY_MAP = {
    books: 'bookId',
    decisions: 'id',
};

/**
 * In case of books page, we need to check if the baseDocId is not 'empty'
 * to avoid query document with invalid id
 * The root level of route is /books, so we need to check if the baseDocId is not empty
 * @param baseDocId - base document id (corresponding to the query parameter)
 */
const baseDocIdIsValid = (baseDocId: string): boolean => !isEmpty(baseDocId);

const usePublicAccessCheck = (): UsePublicAccessCheckResponse => {
    const router = useRouter();
    const { query, route } = router;
    const pageName = route.split('/').at(1) as keyof typeof COLLECTION_MAP;
    const queryKey = QUERY_KEY_MAP[pageName];
    const baseDocId = (query[queryKey] as string) ?? '';
    const collectionName = COLLECTION_MAP[pageName];
    const [documentData, isDocumentLoading, isDocumentError] = useDocumentDataOnce(
        PUBLIC_ACCESS_COLLECTIONS.includes(collectionName) && baseDocIdIsValid(baseDocId)
            ? doc(collection(db, collectionName), baseDocId)
            : null,
    );

    const [hasPublicAccess, setHasPublicAccess] = useState(false);
    const { isLoading: isAuthLoading, user } = useAuth();
    const { loading: isUserLoading, privileges } = useUser();
    const [isLoading, setIsLoading] = useState(true);

    const isDataLoading = useMemo(
        () => isAuthLoading || isDocumentLoading || isUserLoading,
        [isAuthLoading, isDocumentLoading, isUserLoading],
    );

    useEffect(() => {
        if (!isDataLoading) {
            if (ALLOWED_ROUTES.includes(route)) {
                setHasPublicAccess(true);
                setIsLoading(false);

                return;
            }

            // in case privileges are not defined, all values default to undefined
            const { isPaidCustomer, trialEndDate, role } = privileges || {};
            const hasDocumentAccess = !isDocumentError && documentData !== undefined;
            const hasNoPrivileges =
                !user ||
                !(
                    isPaidCustomer &&
                    role &&
                    isAllowedToConsumePaidContent(
                        { isPaidCustomer, role, trialEndDate },
                        user.emailVerified,
                    )
                );

            if (hasDocumentAccess && hasNoPrivileges) {
                setHasPublicAccess(true);
            }
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoading]);

    return [hasPublicAccess, isLoading];
};

export default (hasReadAccess?: boolean): UsePublicAccessCheckResponse =>
    hasReadAccess ? [true, false] : usePublicAccessCheck();
