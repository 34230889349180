import { CreateStripeSubscriptionSession } from '@livv/models/firebaseFunctions';
import { useState, useCallback } from 'react';
import { createStripeSubscriptionSession } from '@functions';

const FAILURE_URL = '/payment/failure';

const useStripe = () => {
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    const createSession = useCallback(
        async ({ requestOrigin }: CreateStripeSubscriptionSession['input']) => {
            try {
                const { data } = await createStripeSubscriptionSession({ requestOrigin });
                const sessionUrl = data.success && data.sessionUrl ? data.sessionUrl : FAILURE_URL;
                setRedirectUrl(sessionUrl);

                return {
                    redirectUrl: sessionUrl,
                };
            } catch {
                setRedirectUrl(FAILURE_URL);

                return {
                    redirectUrl: FAILURE_URL,
                };
            }
        },
        [],
    );

    return { createSession, redirectUrl };
};

export default useStripe;
