import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, Button, Container, Grid, SxProps, Theme, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import TransText from 'next-translate/TransText';
import useTranslation from 'next-translate/useTranslation';
import { FC, useEffect, useState } from 'react';
import ButtonLink from '../Links/ButtonLink';
import PlainLink from '../Links/PlainLink';
import ModalLoader from '@components/Loader/ModalLoader';
import Advantage, { AdvantageType } from '@components/Offer/Advantage';
import { PRICING_PER_USER } from '@utils/consts/livv';
import { CONTACT_APPOINTMENT_URL } from '@utils/consts/urls';
import useUser from '@utils/context/user';
import { useAmplitude } from '@utils/hooks';
import useStripe from '@utils/hooks/useStripe';
import { TrackingEventName } from '@utils/types/amplitude/events';

const containerSx: SxProps = {
    background: 'url("/assets/livv-bg.svg") top center no-repeat',
    backgroundSize: { md: 'contain', xs: '90%' },
    pt: { md: 0, xs: 3 },
};

const pricingTitle: SxProps = {
    color: 'primary.main',
    fontSize: { md: 40, xs: 24 },
};

const pricingCondition: SxProps = {
    color: 'primary.main',
    fontSize: 12,
};

const pricingSubtitleSx: SxProps<Theme> = {
    color: 'grey100',
    fontFamily: (theme: Theme) => theme.typography.h1.fontFamily,
    fontSize: 18,
    pb: 1.5,
    pt: 2,
};

const underlineActionSx: SxProps = {
    fontSize: 12,
};

const Offer: FC = () => {
    const router = useRouter();
    const { t } = useTranslation('offer');
    const { logEvent } = useAmplitude();
    const { privileges } = useUser();
    const { createSession, redirectUrl } = useStripe();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isTrialExpired = privileges?.trialEndDate ? privileges.trialEndDate <= Date.now() : false;

    useEffect(() => {
        createSession({ requestOrigin: window.location.origin });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (redirectUrl && isModalOpen) {
            router.push(redirectUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectUrl]);

    const sendAmplitudeMultipleLicencesButtonEvent = async () => {
        await logEvent({
            name: TrackingEventName.OFFER_MULTIPLE_LICENCES_BUTTON_CLICKED,
            params: {
                trial_expired: isTrialExpired,
            },
        });
    };

    const sendAmplitudePurchaseButtonEvent = async () => {
        await logEvent({
            name: TrackingEventName.PURCHASE_BUTTON_CLICKED,
            params: {
                trial_expired: isTrialExpired,
            },
        });
    };

    const sendAmplitudeSalesAppointmentButtonEvent = async () => {
        await logEvent({
            name: TrackingEventName.SALES_APPOINTMENT_BOOKING_BUTTON_CLICKED,
            params: {
                trial_expired: isTrialExpired,
            },
        });
    };

    const handleSubscribeButtonClick = async () => {
        await sendAmplitudePurchaseButtonEvent();
        if (redirectUrl) {
            await router.push(redirectUrl);
        } else {
            setIsModalOpen(true);
        }
    };

    const CHECK_LIST_ITEM: AdvantageType[] = [
        {
            id: 'thematicPlan',
            img: 'jusdgment.svg',
        },
        {
            id: 'search',
            img: 'noQuery.svg',
        },
        {
            id: 'books',
            img: 'noResult.svg',
        },
        {
            id: 'site',
            img: 'error.svg',
        },
    ];

    return (
        <Container data-test-id="offer" sx={containerSx}>
            <Grid alignItems="center" mt={0} spacing={2} container>
                <Grid md={6} item>
                    <Typography component="h2" sx={pricingTitle}>
                        <TransText
                            components={[<strong key="price">{PRICING_PER_USER}</strong>]}
                            text={t('pricing.price')}
                        />
                    </Typography>
                    <Typography sx={pricingCondition}>{t('pricing.condition')}</Typography>
                    <Typography sx={pricingSubtitleSx}>{t('pricing.title')}</Typography>
                    <Grid direction="column" rowGap={2} container>
                        {CHECK_LIST_ITEM.map((advantage) => (
                            <Advantage key={advantage.id} advantage={advantage} />
                        ))}
                    </Grid>
                    <Box sx={{ display: 'flex', gap: 2, my: 3 }}>
                        <Button
                            disabled={isModalOpen}
                            onClick={handleSubscribeButtonClick}
                            startIcon={<CreditCardIcon />}
                            variant="contained"
                        >
                            {t('actionBuyLicence')}
                        </Button>
                        <ButtonLink
                            href={CONTACT_APPOINTMENT_URL}
                            onClick={sendAmplitudeSalesAppointmentButtonEvent}
                            startIcon={<CalendarMonthIcon />}
                            variant="ghost"
                            openInNewTab
                        >
                            {t('actionAppointment')}
                        </ButtonLink>
                    </Box>
                    <PlainLink
                        href={CONTACT_APPOINTMENT_URL}
                        onClick={sendAmplitudeMultipleLicencesButtonEvent}
                        sx={underlineActionSx}
                        underline="always"
                        openInNewTab
                    >
                        {t('actionMultipleLicences')}
                    </PlainLink>
                </Grid>
                <Grid mb={6} md={6} item>
                    <img
                        alt=""
                        loading="lazy"
                        src="/assets/decision-livv.webp"
                        style={{ maxWidth: '100%' }}
                    />
                </Grid>
            </Grid>
            <ModalLoader message={t('common:loadingMessage')} open={isModalOpen} />
        </Container>
    );
};

export default Offer;
