import { Card, Grid, SxProps, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

const cardContentSx: SxProps = {
    '&:last-child': {
        pb: 0,
    },
    color: 'text.disabled',
    p: 0,
};

export type AdvantageType = {
    id: string;
    img: string;
};

interface AdvantageProps {
    advantage: AdvantageType;
}

const Advantage: FC<AdvantageProps> = ({ advantage }) => {
    const { t } = useTranslation('offer');

    return (
        <Grid direction="row" container item>
            <Card elevation={0}>
                <CardContent sx={cardContentSx}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        paddingX={4}
                        paddingY={2}
                        spacing={2}
                    >
                        <img
                            alt={t(`pricing.checks.${advantage.id}.alt`)}
                            src={`/assets/${advantage.img}`}
                            width={60}
                        />
                        <Typography>
                            <strong>{t(`pricing.checks.${advantage.id}.title`)} </strong>
                            {t(`pricing.checks.${advantage.id}.description`)}
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Advantage;
