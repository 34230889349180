import { Box, Dialog, Typography, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

interface ModalLoaderProps {
    open: boolean;
    message?: string;
}

const dialogPaperSx: SxProps<Theme> = {
    backgroundColor: 'white',
    padding: 2,
};

const boxContainerSx: SxProps<Theme> = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 300,
};

const ModalLoader: FC<ModalLoaderProps> = ({ open, message }) => (
    <Dialog
        PaperProps={{
            sx: dialogPaperSx,
        }}
        aria-labelledby="modal-loader-title"
        maxWidth="sm"
        open={open}
        fullWidth
    >
        <Box sx={boxContainerSx}>
            <img alt="Loading" src="/assets/Loader.svg" style={{ height: 'auto', width: 240 }} />
            {message && (
                <Typography color="primary.main" id="modal-loader-title" mt={2} variant="h2">
                    {message}
                </Typography>
            )}
        </Box>
    </Dialog>
);

export default ModalLoader;
