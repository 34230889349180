import { NextPage } from 'next';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import useWithPublicAccess from '../hooks/usePublicAccessCheck';
import ConnexionFlowLayout from '@components/ConnexionFlowLayout';
import ExperimentalFeatureDialog from '@components/dialogs/ExperimentalFeatureDialog';
import RestrictedPrivilegeFallback from '@components/RestrictedPrivilegeFallback';
import VerifyEmail, { EMAIL_VERIFIED_QUERY_KEY } from '@components/VerifyEmail';
import { sendOnboardingEmail } from '@functions';
import { APP_NAME } from '@utils/consts/livv';
import { useAuth } from '@utils/context/auth';
import useUser from '@utils/context/user';
import { useAlertSnackBar } from '@utils/hooks';
import useSession from '@utils/hooks/useUserSession';

const withPrivilegesRestriction =
    <P extends object>(Component: NextPage<P>, requiredFeatureFlags = ''): NextPage<P> =>
    (props) => {
        const { t } = useTranslation('common');
        const router = useRouter();
        const [hasPublicAccess, isPublicAccessPrivilegeLoading] = useWithPublicAccess(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            props?.hasReadAccess,
        );
        const { loading: isUserLoading, privileges } = useUser();
        const { logout, user } = useAuth();
        const { isSessionValid, isLoading: isUserSessionLoading } = useSession();
        const [successSnackBar, setSuccessMessage] = useAlertSnackBar({
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            severity: 'success',
        });

        const redirectUrl = encodeURIComponent(router.asPath);

        useEffect(() => {
            if (!isSessionValid && user) {
                router.push(`/session-logout?redirectUrl=${redirectUrl}`);
                logout(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [user, isSessionValid]);

        useEffect(() => {
            if (user && user?.emailVerified && router.query?.[EMAIL_VERIFIED_QUERY_KEY]) {
                setSuccessMessage(
                    t('emailVerification:verificationSuccessful', { appName: APP_NAME }),
                );
                window.history.replaceState(
                    null,
                    '',
                    router.asPath.replace(
                        new RegExp(`(\\?|\\&)${EMAIL_VERIFIED_QUERY_KEY}=true`),
                        '',
                    ),
                );

                sendOnboardingEmail({ uid: user?.uid });
            }
            // Display message only once
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [user]);

        if (isUserLoading || isUserSessionLoading || isPublicAccessPrivilegeLoading) {
            return null;
        }

        if (!hasPublicAccess) {
            if (!isSessionValid) {
                return null;
            }

            if (!privileges) {
                router.push(`/signin?redirectUrl=${redirectUrl}`);

                return null;
            }

            const { isAllowedToConsumePaidContent, isEmailVerified } = privileges;

            if (!isEmailVerified) {
                return (
                    <ConnexionFlowLayout>
                        <VerifyEmail />
                    </ConnexionFlowLayout>
                );
            }

            const isProfilePage = router.pathname === '/profile';
            const isPaymentPage = router.pathname.includes('/payment/');
            const isOfferPage = router.pathname === '/offer';

            if (
                !isProfilePage &&
                !isPaymentPage &&
                !isOfferPage &&
                !isAllowedToConsumePaidContent
            ) {
                return (
                    <>
                        <RestrictedPrivilegeFallback />
                        {successSnackBar}
                    </>
                );
            }

            const { isPaidCustomer } = privileges;
            if (isOfferPage && isPaidCustomer) {
                router.push('/');

                return null;
            }

            const { featureFlags } = privileges;

            if (requiredFeatureFlags && !featureFlags?.includes(requiredFeatureFlags))
                return <ExperimentalFeatureDialog />;
        }

        return (
            <>
                <Component {...props} />
                {successSnackBar}
            </>
        );
    };

export default withPrivilegesRestriction;
