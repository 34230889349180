import { TRIAL_PERIOD_DAYS } from '@livv/models/consts/users';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import HeaderBar from '@components/HeaderBar';
import Offer from '@components/Offer/Offer';

const RestrictedPrivilegeFallback: FC = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <HeaderBar
                subtitle={t('withPrivilegesRequired.trialOver.subTitle', {
                    trialDays: TRIAL_PERIOD_DAYS,
                })}
                title={t('withPrivilegesRequired.trialOver.title')}
            />
            <Offer />
        </>
    );
};

export default RestrictedPrivilegeFallback;
